import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, ReplaySubject, take, tap } from 'rxjs';
import { User, UserResponse } from '@sbd-account-portal/app/core/user/user.types';
import { environment } from '@sbd-account-portal/app/environments/environment';
import { Router } from '@angular/router';
import { AccountPortalAuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  currentAccountUser = signal<User>(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _router: Router, private _authService: AccountPortalAuthService) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    return this._httpClient.get<UserResponse>(environment.apiLocalUrl + '/user/user-info').pipe(
      map(response => {
        if (!response.success) {
          this._authService.signOut().pipe(take(1)).subscribe(() => {
            this._router.navigate(['/sign-in']);
          });

          return null;
        }

        this._user.next(response.data.user);
        this.currentAccountUser.set(response.data.user);
        return response.data.user;
      }),
    );
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      }),
    );
  }

}
